import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import Button from '../elements/Button';
import ROUTES from '../../data/routes'
import AccountValidationStatus from '../../data/enumerators/accountValidation';
import utc from 'dayjs/plugin/utc'

const ReceiptDetails = ({ details }) => {
  const {
    id,
    account,
    agency,
    amount,
    bank,
    fee,
    name,
    type,
    createdAt,
    accountStatus,
    cashoutLimitTime,
    systemDataMatera,
    scheduleDate
  } = details;

  dayjs.extend(utc);

  const date = dayjs(createdAt).format("DD/MM/YYYY");

  const scheduleDateFormated = dayjs.utc(scheduleDate).format("DD/MM/YYYY");

  const translate = useCallback((path) => {
    return I18n.t(`cashoutPage.${path}`)
  }, [I18n]);

  const descriptionSchedule = useMemo(() => {
    const currentDateTime = dayjs();
    const currentHour = currentDateTime.hour();
    const systemDate = dayjs(systemDataMatera, "YYYY-MM-DDTHH:mm:ssZ");
    const isAccountPending = AccountValidationStatus.PENDING === accountStatus;

    if (isAccountPending) {
      return translate("receipt.operationTime.scheduleWhenAccountPending");
    }

    if (currentDateTime.date() === systemDate.date() && currentHour <= cashoutLimitTime) {
      return translate("receipt.operationTime.scheduleWhenAccountTwo");
    }

    if (currentDateTime.isAfter(systemDate, 'day')) {
      return translate("receipt.operationTime.scheduleWhenAccountThree");
    }

    if (currentHour > cashoutLimitTime) {
      return translate("receipt.operationTime.scheduleWhenAccountFour");
    }

    return `${translate("receipt.operationTime.schedule")} ${date}`;
  }, [cashoutLimitTime, account, systemDataMatera]);

  const descriptionNow = useMemo(() => {
    const currentDateTime = dayjs();
    const currentHour = currentDateTime.hour();
    const systemDate = dayjs(systemDataMatera, "YYYY-MM-DDTHH:mm:ssZ");

    if (currentDateTime.date() === systemDate.date() && currentHour <= cashoutLimitTime) {
      return translate("receipt.operationTime.scheduleWhenAccountTwo");
    }

    if (currentDateTime.isAfter(systemDate, 'day')) {
      return translate("receipt.operationTime.scheduleWhenAccountThree");
    }

    if (currentHour > cashoutLimitTime) {
      return translate("receipt.operationTime.scheduleWhenAccountFour");
    }

    return `${translate("receipt.operationTime.now.one")} ${date}`;
  }, [cashoutLimitTime, account, systemDataMatera]);

  return (
    <div className="cashout-details">
      <h1 className="cashout-details__title">
        {translate("title")}
      </h1>

      <div className="cashout-details__content">
        <div className="cashout-details__content__icon">
          <i className="fa fa-check" />
        </div>

        <h3 className="cashout-details__content__title">
          {type === "NOW" ? translate('receipt.nowTitle') : translate('receipt.scheduleTitle')}
        </h3>

        {id && (
          <div className="cashout-details__content__information-id">
            <p>{translate('receipt.operationNumber')}</p>
            <span>{id}</span>
          </div>
        )}

        <hr className="cashout-details__content__divider" />

        <div className="cashout-details__content__row">
          <div className="cashout-details__content__information">
            <p>{translate('receipt.value')}</p>
            <span>{amount.toLocaleString('pt-br', { style: "currency", currency: "BRL" })}</span>
          </div>

          <div className="cashout-details__content__information cashout-details__content__information__right">
            <p>{translate('receipt.from')}</p>
            <span>{name}</span>
          </div>
        </div>

        <div className="cashout-details__content__row">
          <div className="cashout-details__content__information">
            <p>{translate('bank')}</p>
            <span>{bank}</span>
          </div>

          <div className="cashout-details__content__information cashout-details__content__information__right">
            <p>{translate('receipt.type')}</p>
            <span>{type === "NOW" ? "Transferência" : "Transferência agendada"}</span>
          </div>
        </div>

        <div className="cashout-details__content__row">
          <div className="cashout-details__content__information">
            <p>{translate('agency')}</p>
            <span>{agency}</span>
          </div>

          <div className="cashout-details__content__information">
            <p>{translate('account')}</p>
            <span>{account}</span>
          </div>

          {fee > 0 && (
            <div className="cashout-details__content__information cashout-details__content__information__right">
              <p>{translate('receipt.fee')}</p>
              <span>{fee.toLocaleString('pt-br', { style: "currency", currency: "BRL" })}</span>
            </div>
          )}
        </div>

        {type === "SCHEDULE" && (
          <div className="cashout-details__content__information cashout-details__content__information__left">
            <p>{translate('receipt.scheduleTo')}</p>
            <span>{scheduleDateFormated}</span>
          </div>
        )}

        <hr className="cashout-details__content__divider" />

        <div className="cashout-details__content__information cashout-details__content__information__left">
          <p>{translate('note.title')}</p>
          <span>
            {translate('note.description1')}
            {' '}{cashoutLimitTime}h{' '}
            {translate('note.description2')}
          </span>

            {type === "NOW" && (
              <p className="cashout-details__operation-time">
                <i className="fa fa-check" />
                {descriptionNow}
              </p>
            )}

          {type === "SCHEDULE" && (
            <p className="cashout-details__operation-time">
              <i className="fa fa-check" />
              {descriptionSchedule}
            </p>
           )}
        </div>

        <hr className="cashout-details__content__divider" />

        <Link to={ROUTES.HOME}>
          <Button>{translate('receipt.backToHome')}</Button>
        </Link>
      </div>
    </div>
  )
}

export default ReceiptDetails;